
.center1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.profile {
  width: 90%;
  max-width: 700px;
  margin: 0 auto;
  border: 1px solid #dcdcdc;
  padding: 20px;
  text-align: center;
  background-color: #f5f5f5;
  border-radius: 5px;
  height: 100vh; /* Set the desired height of the profile component */
  overflow: auto;
}

.profile h1 {
  margin-bottom: 20px;
}

.profile p {
  text-align: left;
  margin-bottom: 10px;
}

.profile input,
.profile select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.profile .form-row {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  /* justify-content: space-between; */
}

.profile .form-row > div {
  display: flex;
  flex-direction: column;
  flex: 1;
}


.profile .form-row input {
  flex: 1;
}

.profile .form-row .slider-container {
  width: calc(50% - 5px);
  display: flex;
  flex-direction: column;
  align-items: center; /* Modified */
}

.profile .form-row .slider {
  width: 100%;
}

.profile .form-row .slider-label {
  margin-bottom: 5px;
  color: black; /* Modified */
}

.profile .form-row .slider-value {
  margin-top: 5px;
  font-weight: bold;
}

.profile button {
  padding: 10px 20px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.profile span {
  color: black; /* Modified */
  text-decoration: none; /* Modified */
  cursor: default; /* Modified */
}

.datepicker-container {
  flex: 1;
  margin-right: 10px;
}

.datepicker-container .react-datepicker-wrapper {
  width: 100%; /* Adjust the width as needed */
}

.profile label {
  display: block;
  text-align: left;
  font-size: 12px;
  color: #888;
  margin-bottom: 5px;
  margin-left: 2px;
  display: flex;
  align-items: center;
}

.profile centerlabel {
  display: block;
  text-align: center;
  font-size: 12px;
  color: #000000;
  margin-bottom: 5px;
  margin-left: 2px;
  display: flex;
  align-items: center;
}

.profile label .required-indicator {
  color: red;
  margin-left: 5px;
}

.save-button {
  width: 100%;
  height: 50px; /* Adjust the height as needed */
  margin-top: 20px; /* Adjust the margin as needed */
}

.sign-out-container {
  position: absolute;
  top: 10px;
  left: 10px;
}

.sign-out-container .sign-out-button {
  padding: 10px 20px;
  background-color: #ff8888;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.profile .form-row.space-between-rows + .form-row {
  margin-top: 50px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.new-button {
  padding: 10px 20px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button-container1 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.new-button1 {
  padding: 10px 20px;
  background-color: #000000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.resume-upload-text {
  margin-left: 10px;
  color: #888 !important;
  font-size: 14px;
  line-height: 1.2;
  max-width: 225px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-align: left;
}



.auth-title {
  font-size: 2rem;
  color: #003049;
  margin-bottom: 1rem;
}


.profile .centerlabel-container label {
  display: flex;
  justify-content: center;
}

