/* admin-users.css */

.admin-users-component {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .admin-users-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center; /* Center the title */
  }
  
  .users-table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .users-table th,
  .users-table td {
    padding: 12px;
    border: 1px solid #ddd;
  }
  
  .users-table th {
    background-color: #f5f5f5;
    font-weight: bold;
    font-size: 16px;
  }
  
  .users-table td {
    text-align: center;
    font-size: 16px;
  }
  
  .users-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .delete-btn {
    padding: 8px 16px;
    background-color: #ff6666;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .delete-btn:hover {
    background-color: #ff6666;
  }
  
  /* Additional styles for input and button */
  
  .input-section .input-container {
    display: flex;
    align-items: center; /* Vertically center the elements */
  }
  
  .input-section input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    margin-right: 10px; /* Add margin to separate from the button */
  }
  
  .save-users-btn {
    padding: 12px 24px;
    background-color: #3498db; /* Blue color for the button */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  