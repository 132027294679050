.auth {
  width: 90%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.auth-title {
  font-size: 2rem;
  color: #003049;
  margin-bottom: 1rem;
}

.auth__error {
  padding: 10px 0;
  background-color: #f8d7da;
  color: #721c24;
  margin-bottom: 1rem;
}

.auth__loading {
  padding: 10px 0;
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group input {
  width: 100%;
  height: 40px;
  font-size: 16px;
  padding: 0 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn-register {
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  height: 40px;
  font-size: 16px;
}

.btn-register:hover {
  background-color: #2980b9;
}

.btn-login {
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  height: 40px;
  font-size: 16px;
  margin-top: 10px;
}

.btn-login:hover {
  background-color: #2980b9;
}

.auth__text {
  font-size: 14px;
  color: #999;
  margin-top: 1rem;
}

.auth__textright {
  font-size: 14px;
  color: #999;
  margin-top: 1rem;
  align-content: right;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 10px 16px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.back-button:hover {
  background-color: #2980b9;
}

.password-input {
  position: relative;
}

.password-toggle-btn {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.password-toggle-btn:focus {
  outline: none;
}

.password-toggle-btn svg {
  width: 20px;
  height: 20px;
  color: #999;
}

.password-toggle-btn:hover svg {
  color: #555;
}
