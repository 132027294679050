.query-component {
  max-width: 800px;
  margin: 0 auto;
}

.input-section {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.input-header {
  position: relative;
}

.input-header .reset-button {
  position: absolute;
  top: -20px; /* Adjust the top value as needed */
  right: 10px;
  padding: 6px 12px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.input-header .reset-button:hover {
  background-color: #2980b9;
}



.input-section h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.attribute-inputs {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.attribute-inputs label {
  margin-right: 20px;
  font-weight: normal;
  font-size: 16px;
  color: #333;
}

.attribute-inputs input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
}

.run-query-btn {
  padding: 12px 24px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.csv-btn {
  padding: 12px 24px;
  background-color: #217346;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  
}

.results-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.results-section {
  width: fit-content;
  margin-top: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}




.results-section h2 {
  margin-bottom: 10px;
}

.table-container {
  overflow-x: auto;
}
.results-table {
  width: auto;
  table-layout: auto;
  border-collapse: collapse;
  margin-top: 20px;

}

.results-table th,
.results-table td {
  padding: 12px;
  border: 1px solid #ddd;
}

.results-table th {
  background-color: #f5f5f5;
  font-weight: bold;
  font-size: 16px;
}

.results-table td {
  text-align: center;
  font-size: 16px;
}

.results-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.export-csv-btn {
  padding: 12px 24px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  font-size: 16px;
}

.back-btn {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 10px 16px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.back-btn:hover {
  background-color: #2980b9;
}

.users-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px 16px;
  background-color: #FFA500;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.users-btn:hover {
  background-color: #FFA500;
}

input[readOnly],
select:disabled {
  background-color: #f0f0f0; /* Use desired gray color */
  color: #333333; /* Use desired darker color */
  cursor: not-allowed;
}


.date-picker-wrapper-disabled {
  background-color: #f0f0f0; /* Use desired gray color */
  color: #999999;
    pointer-events: none;
}

.view-profile-button {
  padding: 8px 16px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.view-profile-button:hover {
  background-color: #2980b9;
}

.inactive-button {
  padding: 8px 8px;
  background-color: #ff6666;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.inactive-button:hover {
  background-color: #ff6666;
}


.input-section .form-row {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  /* justify-content: space-between; */
}

.input-section .form-row > div {
  display: flex;
  flex-direction: column;
  flex: 1;
}


.input-section .form-row input {
  flex: 1;
}

.input-section .form-row .slider-container {
  width: calc(50% - 5px);
  display: flex;
  flex-direction: column;
  align-items: center; /* Modified */
}

.input-section .form-row .slider {
  width: 100%;
}

.input-section .form-row .slider-label {
  margin-bottom: 5px;
  color: black; /* Modified */
}

.input-section .form-row .slider-value {
  margin-top: 5px;
  font-weight: bold;
}
