.verifyEmail {
  width: 90%;
  max-width: 500px;
  box-shadow: 0px 0px 4px hsl(210, 12%, 75%);
  text-align: center;
  padding: 40px 20px;
  background-color: hsl(210, 10%, 98%);
}

.verifyEmail h1 {
  font-weight: 300;
  margin-top: 0;
}

.verifyEmail > p {
  line-height: 25px;
}

.verifyEmail > span {
  color: gray;
}

.btn-resend-email {
  margin-top: 35px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  height: 40px;
  font-size: 16px;
}

.btn-resend-email:disabled {
  /* Styles for disabled state */
  background-color: #ccc;
  color: #888;
  cursor: not-allowed;
}

.btn-resend-email:not(:disabled):hover {
  /* Styles for hover when not disabled */
  background-color: #2980b9;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
